
import { Component, Prop } from 'vue-property-decorator';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { mixins } from 'vue-class-component';
import AppName from '../mixin/AppName.vue';
import { getCountryCode } from '@/utils/LocationUtils';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

interface InputAttributes {
  errorMessage: string;
  placeholder: string;
  labelClass: string;
  phone: string | null;
  variant: string;
  disabled: boolean;
}

@Component({
  components: {
    VuePhoneNumberInput
  }
})
export default class FormInput extends mixins(AppName) {
  @Prop() inputAttrs!: InputAttributes;
  @Prop() input!: string;
  public countryCode = 'NZ';
  public colour = '#F6861F';
  public value = '';

  mounted() {
    this.colour = this.getAppColour(false);
    if (!this.formatPhone()) {
      this.fetchCountryCode();
    }
  }

  public async fetchCountryCode() {
    await getCountryCode().then((code: string) => {
      this.countryCode = code;
    });
  }

  public formatPhone() {
    if (this.inputAttrs.phone) {
      try {
        const phoneNumber = parsePhoneNumber(this.inputAttrs.phone);
        this.setPhoneValue(phoneNumber);
        return true;
      } catch (error) {
        try {
          //No country code so default to New Zealand
          const phoneNumber = parsePhoneNumber(this.inputAttrs.phone, 'NZ');
          this.setPhoneValue(phoneNumber);
          return true;
        } catch (error) {
          this.value = this.inputAttrs.phone;
          this.countryCode = 'NZ';
          return true;
        }
      }
    }

    return false;
  }

  public setPhoneValue(phoneNumber: any) {
    if (phoneNumber !== undefined) {
      this.value = phoneNumber.formatNational() ?? this.value;
      this.countryCode = phoneNumber.country ?? this.countryCode;
      this.updateInput({
        ...phoneNumber,
        isValid: isValidPhoneNumber(phoneNumber.number, phoneNumber.country)
      });
    } else {
      this.value = this.inputAttrs.phone ?? '';
      this.countryCode = 'NZ';
    }
  }

  public updateInput(newValue: any) {
    if (
      newValue.countryCallingCode &&
      newValue.nationalNumber &&
      newValue.isValid
    ) {
      const formatedPhoneNumber = `+${newValue.countryCallingCode} ${newValue.nationalNumber}`;
      this.inputAttrs.phone = formatedPhoneNumber;
    } else if (newValue.phoneNumber) {
      this.inputAttrs.phone = null;
    } else {
      this.inputAttrs.phone = '';
    }
    this.$emit('input', this.inputAttrs.phone);
  }
}
